<div class="section-contact site-width">
  <h2 class="top-heading">Contact</h2>
  <div class="two-columns">
    <div class="column-1 column">
      <h3>Links</h3>
      <ul>
        <li><a href="https://www.cityteam.org">Cityteam</a></li>
        <li><a href="https://www.youtube.com/user/cityteam/">Cityteam's YouTube Channel</a></li>
        <li><a href="https://www.linkedin.com/in/lmalabuyo/">LinkedIn</a></li>
      </ul>
    </div>
    <div class="column-2 column">
      <h3>Resume</h3>
      <p>See my resume on <a href="https://www.linkedin.com/in/lmalabuyo/">LinkedIn</a> or contact me at lmalabuyo[a]gmail.com for an in-depth resume.</p>
    </div>
  </div>
  <a scrollTo href="#home" class="back-to-top">
    <svg class="icon-top" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48" fill="#fff">
      <path d="M14.83 30.83L24 21.66l9.17 9.17L36 28 24 16 12 28z"/>
      <path d="M0 0h48v48H0z" fill="none"/>
    </svg>
    <span>Back to top</span>
  </a>
</div>
