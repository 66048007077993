<div #bgList class="backgrounds">
  <div *ngIf="showStep === 1"
       [@fadeInOut]="showStep === 1"
       class="bg1">
  </div>
  <div *ngIf="showStep === 2"
       [@fadeInOut]="showStep === 2"
       class="bg2">
  </div>
</div>
<div class="headings">
  <h1>Lemuel Malabuyo <small>Photographer // Video Editor // Cameraman</small>
    <div class="buttons">
      <button class="button see-reel" scrollTo scrollTargetSelector="#reel">See demo reel</button>
      <button class="button see-portfolio" scrollTo scrollTargetSelector="#videos">See portfolio</button>
    </div>
  </h1>
</div>
