<div [ngClass]="{'modal-content--open': isOpen}"
     (click)="closeModal($event)"
     class="modal-content close-element">
  <div class="modal-content-inner">
    <div [ngClass]="{'shown': modaltype=='video'}" class="video-container">
      <iframe #youtubeIframe class="youtube-iframe" frameborder="0" allowfullscreen></iframe>
    </div>
    <div [ngClass]="{'shown': modaltype=='photo'}" class="photo-container">
      <img #imgEl alt="">
    </div>
    <ng-content></ng-content>
    <a (click)="closeModal($event)"
        class="modal-content__close close-element">
      <svg class="close-element" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path class="close-element" d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z"/>
        <path class="close-element" d="M0 0h48v48H0z" fill="none"/>
      </svg>
    </a>
  </div>
</div>
