<div class="inset">
  <div class="site-width">
    <p class="intro">A results-oriented, hands-on and multi-skilled visual journalist with over {{yearsExperience}} years of experience coupled with excellent written, oral and innovative visual communication abilities.</p>

    <div class="skills">
      <h3>Skills &amp; Abilities</h3>
      <ul>
        <li>Video</li>
        <li>Final Cut Pro</li>
        <li>Video editing</li>
        <li>Motion graphics</li>
        <li>Compressor</li>
        <li>Storyboarding</li>
        <li>Mac OSX</li>
        <li>Event photography</li>
        <li>Video production</li>
        <li>Adobe Premiere</li>
        <li>Adobe After Effects</li>
        <li>Adobe Lightroom</li>
        <li>Adobe Photoshop</li>
        <li>YouTube</li>
        <li>Social Networking</li>
        <li>Sound</li>
        <li>Lighting</li>
        <li>Documentary</li>
        <li>Photojournalism</li>
        <li>Event planning</li>
        <li>Nonprofits</li>
        <li>Color correction</li>
        <li>Photo editing</li>
      </ul>
    </div>
    <ul class="intro-bullets">
      <li>Experience in the abilities to strategize, create and implement presentations that documents and markets the message that needs to be heard per the customer parameters.</li>
      <li>Capabilities in all aspects of video production, from a project’s early development to its finished presentation.</li>
    </ul>
  </div>
</div>
