<header [ngClass]="{'nav-up': isScrollDirectionUp}">
  <div class="site-width">
    <div scrollTo scrollTargetSelector="#home"
         class="logo-lockup">
      <h1>Aperture <span>Wide Open</span></h1>
      <h2>The Online Portfolio of Lemuel Malabuyo</h2>
      <h1 class="mobile-only">AWO</h1>
    </div>
    <nav>
      <ul>
        <li class="nav-item" [ngClass]="{'selected': currentNav == 'home'}"><a scrollTo href="#home">Home</a></li>
        <li class="nav-item" [ngClass]="{'selected': currentNav == 'about'}"><a scrollTo href="#about">About</a></li>
        <li class="nav-item" [ngClass]="{'selected': currentNav == 'videos'}"><a scrollTo href="#videos">Videos</a></li>
        <li class="nav-item" [ngClass]="{'selected': currentNav == 'photos'}"><a scrollTo href="#photos">Photos</a></li>
        <li class="nav-item nav-contact" [ngClass]="{'selected': currentNav == 'contact'}"><a scrollTo href="#contact">Contact</a></li>
      </ul>
    </nav>
  </div>
</header>
