<div class="wrapper">
  <site-header></site-header>
  <section-hero id="home"></section-hero>
  <div #aboutSection class="section" id="about">
    <section-about></section-about>
  </div>
  <div #reelSection class="section" id="reel">
    <section-reel></section-reel>
  </div>
  <div #portfolioSection class="section">
    <section-portfolio></section-portfolio>
  </div>
  <div #contactSection class="section" id="contact">
    <section-contact></section-contact>
  </div>
  <site-footer></site-footer>
</div>
